<template>
  <div>
    <!-- PC 端登录页 / -->
    <div class="login-bg hidden-sm-and-down">
      <div class="login-bg__image">
        <img src="@/assets/login/bg.webp" />
      </div>
      <!-- 登录表单卡片 -->
      <div class="login-bg__card">
        <div class="font26 N2" style="margin: 72px 0 36px">
          登 录
          <el-tag v-if="env" class="tag" size="mini" type="warning">{{
            env
          }}</el-tag>
        </div>
        <!-- 微信登录二维码 -->
        <LoginWeChat
          v-if="loginMethod == 'wechat'"
          platform="wechat"
          @ok="onLoginSuccess"
        >
        </LoginWeChat>
        <!-- 钉钉登录二维码 -->
        <LoginDingtalk
          v-if="loginMethod == 'dingtalk'"
          platform="dingtalk"
          @ok="onLoginSuccess"
          @cancel="loginMethod = 'password'"
        >
        </LoginDingtalk>
        <div class="form" v-if="loginMethod == 'password'">
          <PcPassword @ok="onLoginSuccess" />
          <!-- 第三方登录 -->
          <div class="third-platform" v-if="HOST_CODE !== 'BY'">
            <el-divider> <span class="N3">其它登录方式</span> </el-divider>
            <div class="column-center" style="margin-top: 32px">
              <div class="flex-between">
                <div class="app-icon wechat" @click="loginMethod = 'wechat'" />
                <div
                  class="app-icon dingtalk"
                  @click="loginMethod = 'dingtalk'"
                />
                <!-- <div class="app-icon alipay closed" /> -->
              </div>
            </div>
          </div>
        </div>
        <div v-else class="blue bottom-back-button">
          <el-button
            icon="el-icon-arrow-left"
            type="text"
            @click="loginMethod = 'password'"
            >工号密码登录</el-button
          >
        </div>
      </div>
    </div>
    <!-- 移动端登录页 / -->
    <div class="login-bg hidden-md-and-up">
      <!-- 登录表单卡片 -->
      <H5Password v-if="loginMethod == 'password'" @ok="onLoginSuccess" />
    </div>

    <!-- 页脚 -->
    <div class="footer N4 font12 padding16 hidden-sm-and-down">
      <span>{{ companyInfo[HOST_CODE].url }}</span>
      <span
        @click="to('miit')"
        :class="{ link: !isElectron, padding8: true }"
        >{{ companyInfo[HOST_CODE].miit }}</span
      >
      <span @click="to('beian')" :class="{ link: !isElectron }">{{
        companyInfo[HOST_CODE].beian
      }}</span>
      <!-- !暂时隐藏线路切换 -->
      <el-dropdown size="mini" @command="onChangeHost">
        <span class="N4 font12 padding8 link">{{ host.name }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="host in hostList"
            :key="host.code"
            :command="host"
          >
            <div class="dot bg-G1" />
            <span>{{ host.name }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import LoginWeChat from "@/components/LoginWeChat";
import LoginDingtalk from "@/components/LoginDingtalk";
import PcPassword from "./components/PcPassword";
import H5Password from "./components/H5Password";
import host from "../../api/host"; // 可用线路节点列表
import { getUserInfo } from "@/api/dataService";
import isElectron from "is-electron"; // 判断当前是否嵌入在软件中
export default {
  components: { PcPassword, H5Password, LoginWeChat, LoginDingtalk },
  data() {
    return {
      hostList: host.getHostList(), // 可用线路节点列表
      HOST_CODE: "MR",
      host: host.getHost(), // 当前线路节点
      env: { local: "本地版", develop: "开发版", trial: "学习版", release: "" }[
        this.$ENV
      ],
      companyInfo: {
        MR: {
          url: "© GEEMRO.COM",
          miit: "浙ICP备2021026897号-3",
          beian: "浙公网安备33010202002386号",
        },
        CS: {
          url: "© GEEMRO.COM",
          miit: "浙ICP备2021026897号-3",
          beian: "浙公网安备33010202002386号",
        },
        SC: {
          url: "© GEEMRO.COM",
          miit: "浙ICP备2021026897号-3",
          beian: "浙公网安备33010202002386号",
        },
        BY: {
          url: "© GEEMRO.CN",
          miit: "浙ICP备2022035196号-1",
          beian: "浙公网安备33010202002386号",
        },
      },
      loginMethod: "password", // 可选值: password(工号密码登录) phone(手机验证码登录) wx(微信)
      isElectron: false, // 是否桌面应用环境
      iframeSrc: null, // 开放平台登录二维码
    };
  },

  async mounted() {
    this.HOST_CODE = localStorage.HOST_CODE;
    console.log("HOST_CODE", this.HOST_CODE);
    if (this.$route.query?.code)
      window.parent.postMessage(
        { method: "codeMessage", data: this.$route.query },
        "*"
      ); // 开放平台登录
    if (this.env) document.title = `杰姆龙用户中心 (${this.env})`; // [开发版]或[学习版]标记
    const { logout } = this.$route.query;
    const user = JSON.parse(localStorage.getItem("user"));
    const token = this.$cookie.get("TOKEN");
    if (logout) {
      const [, err] = await this.$post("logout"); // 请求退出
      if (err) return this.$msg(err);
      localStorage.removeItem("user");
      this.$cookie.remove("TOKEN");
      this.$router.replace("/");
    } else if (token && user?.name) {
      const [user, msg] = await getUserInfo();
      if (msg) return false;
      this.onLoginSuccess(user); // 同时有 token 和用户 name 判断为已登录, 直接跳到工作台
    }
  },

  methods: {
    // 登录成功回调
    onLoginSuccess(user) {
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("geemro_user", JSON.stringify(user));
        localStorage.setItem("companyName", user.companyName);
        let userName = `${user.name}+id(${user.userId})`;
        this.$aegis.setConfig({ uin: userName });
      }
      const { app_name, redirect_url } = this.$route.query;
      // PMS桌面应用版软件使用 Electron 环境, 需将 TOKEN 传递过去
      // if (app_name && isElectron()) return window.parent.postMessage(JSON.stringify(user), '*')
      // 若为网页版, 则需 sso 登录, 根据重定向地址直接跳转
      if (app_name && redirect_url)
        return this.ssoLogin(app_name, redirect_url);
      // 其它情况跳转到工作台
      this.$router.replace("/dashboard");
    },

    // 登录到子系统, 并跳转至回调地址
    async ssoLogin(appName, redirectUrl) {
      const [, err] = await this.$post("ssoLogin", { appName }); // 请求单点登录
      if (err) return this.$msg(err);
      window.location.href = `${redirectUrl}?token=${this.$cookie.get(
        "TOKEN"
      )}`;
    },

    // 扫码登录成功回调
    onScanCodeFail() {
      this.loginMethod = "password";
      return this.$msg.warning("当前微信未绑定员工账号, 请使用密码登录");
    },

    // 外部链接跳转
    to(url) {
      // if (isElectron()) return false // 桌面应用环境不跳转
      if (url == "miit") {
        window.open("https://beian.miit.gov.cn"); // 工信部备案
      } else if (url == "beian") {
        window.open(
          "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202002386"
        ); // 公安备案
      }
    },

    // 切换线路
    onChangeHost(targetHost) {
      this.host = host.getHost();
      host.toggleHost(targetHost.code);
      this.HOST_CODE = localStorage.HOST_CODE;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/login-page.scss";
.tabs {
  width: 270px;
  margin: 0 auto 8px;
}
.form {
  width: 370px;
  margin: 56px auto 0;
  text-align: right;
}
.third-platform {
  position: absolute;
  width: 370px;
  bottom: 56px;
}
.third-platform .app-icon {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 50%;
  background-image: url(https://gw.alipayobjects.com/mdn/prod_resou/afts/img/A*wLosTZsNyYcAAAAAAAAAAABjARQnAQ);
  width: 56px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: middle;
  transition: all 0.2s;
  margin-right: 32px;
}
.third-platform .app-icon:last-of-type {
  margin-right: 0;
}
.third-platform .app-icon:hover {
  transform: scale(1.05);
}
.third-platform .wechat {
  background-position: 0 -119px;
}
.third-platform .dingtalk {
  background-position: 0 -59.5px;
}
.wechat:hover {
  box-shadow: 0px 2px 16px -4px #227f05;
}
.dingtalk:hover {
  box-shadow: 0px 2px 16px -4px #257ed5;
}
.alipay:hover {
  box-shadow: 0px 2px 16px -4px #257ed5;
}
.closed::before {
  position: absolute;
  left: 0;
  content: "未开通";
  opacity: 0;
  background: #00000088;
  text-align: center;
  width: 56px;
  border-radius: 50%;
  line-height: 56px;
  font-size: 12px;
  color: #fff;
  transition: all 0.2s;
}
.closed:hover::before {
  opacity: 1;
}
// 扫码页面的返回密码登录按钮
.bottom-back-button {
  position: absolute;
  width: 100%;
  bottom: 48px;
}
// 底部页脚
.footer {
  position: absolute;
  text-align: center;
  width: 600px;
  left: calc(50% - 300px);
  bottom: 0;
  // border: 1px #f00 solid;
}
.footer .link {
  transition: all 0.5s;
  cursor: pointer;
}
.footer .link:hover {
  color: #00a1ff;
  opacity: 0.8;
}
.dot {
  width: 6px;
  height: 6px;
  line-height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px 1px 0;
}
</style>


